export type NotificationType = "error" | "warning" | "info" | "success"
type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>
type Notification = {
  title?: string
  description?: string | string[]
  type: NotificationType
  /* Duration in seconds. 5 seconds by default */
  duration: number
}

type NotificationWithId = Notification & { id: string }

export const useNotifications = () => {
  const notifications = useState<NotificationWithId[]>(() => [])

  function displayNotification({
    duration = 5,
    type = "info",
    ...notification
  }: Optional<Optional<Notification, "type">, "duration">) {
    const id = crypto.randomUUID()
    const notificationWithId = { ...notification, type, duration, id }
    notifications.value = [...notifications.value, notificationWithId]

    setTimeout(() => {
      notifications.value = notifications.value.filter((n) => n.id != id)
    }, duration * 1000)
  }

  const { t } = useI18n()

  const { getErrorText } = useLocalisedError()

  function displayError(error: unknown) {
    const id = crypto.randomUUID()
    const duration = 8
    const notificationWithId: NotificationWithId = {
      id,
      title: t("error.title"),
      type: "error",
      description: getErrorText(error),
      duration,
    }
    notifications.value = [...notifications.value, notificationWithId]

    setTimeout(() => {
      notifications.value = notifications.value.filter((n) => n.id != id)
    }, duration * 1000)
  }

  return { notifications, displayNotification, displayError }
}
